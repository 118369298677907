
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import CMSHead from '@utility/CMSHead/CMSHead';
import HardCodedHorizontalBannerComponent from '@molecules/HardCodedHorizontalBannerComponent/HardCodedHorizontalBannerComponent';
import Page from '@organisms/Page/Page';
import useCmsPage from '@hooks/useCmsPage';
import createFallback, { oneDayInSeconds } from '@helpers/fallback';
const label = 'homePage';
const Index = () => {
    const { cmsPage, isLoading } = useCmsPage(label);
    return (<>
      <CMSHead cmsPage={cmsPage}/>
      <Page cmsPage={cmsPage}>{cmsPage?.uid === 'homePage' && <HardCodedHorizontalBannerComponent />}</Page>
    </>);
};
async function getStaticProps({ locale = 'sv' }) {
    const fallback = await createFallback('ContentPage', label);
    return {
        props: {
            fallback,
            label,
        },
        revalidate: oneDayInSeconds,
    };
}
export default Index;

    async function __Next_Translate__getStaticProps__191c223c8ff__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__191c223c8ff__ as getStaticProps }
  